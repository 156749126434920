<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <a-tooltip placement="top">
            <template slot="title"><span>Add Staff Type</span></template>
            <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-staff-type-modal @click="$bvModal.show('add-update-staff-type-modal'), resetForm()">
              <i class="fa fa-plus"/> Add Staff Type
            </a>
          </a-tooltip>
          <table class="table table-sm table-bordered text-center" id="staff_types_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(staff_type, index) in staff_types" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ staff_type.name }}</td>
              <td>{{ staff_type.status === 1 ? 'Active' : 'Inactive' }}</td>
              <td>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(staff_type.id), $bvModal.show('add-update-staff-type-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
                <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(staff_type.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="staff_types.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
      <add-update-staff-type
        :staff_type="staff_type"
        :btnEdit="btnEdit"
        @getStaffTypes="getStaffTypes"
        @resetForm="resetForm"
        ref="addUpdateStaffType">
      </add-update-staff-type>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateStaffType from '@/views/staffType/modal/addUpdateStaffType'
import $ from 'jquery'

export default {
  name: 'List',
  components: {
    Empty,
    addUpdateStaffType,
  },
  data() {
    return {
      staff_types: [],
      staff_type: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getStaffTypes()
  },
  methods: {
    getStaffTypes() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/staff-types')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.staff_types = response.data.staff_types
          setTimeout(function () {
            $('#staff_types_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.staff_type = {}
      this.btnEdit = true
      apiClient.get('api/staff-types/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.staff_type = response.data.staff_type
        }
      })
    },
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/staff-types/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#staff_types_list').DataTable().destroy()
              this.getStaffTypes()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.staff_type = {}
    },
  },
}
</script>

<style scoped>

</style>
